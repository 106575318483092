import React from "react";
import networkl from "../Assets/feru.png";
import networkr from "../Assets/networkr.png";
import networkc from "../Assets/networkc.png";
import current from "../Assets/current.png";
import profileimage from "../Assets/profileimage.jpg";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";

const Network = () => {
  return (
    <>
      <div className="network-container">
        <p className="networktext">Network</p>

        <Grid
          container
          style={{
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
          spacing={5}
        >
          <Grid
            item
            md={12}
            sm={12}
            lg={12}
            xs={12}
            style={{
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <a
              href="https://0xpioneers.io/"
              class="js-create_video ag-create_item"
              target="_blank"
            >
              <svg
                viewBox="0 0 411 431"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
              >
                <polygon
                  class="cls-1"
                  points="0.5 430.5 340.31 430.5 410.5 361.03 410.5 0.5 0.5 0.5 0.5 430.5"
                ></polygon>
                <polygon
                  class="cls-2"
                  points="355.17 430.5 410.33 375.33 410.33 430.5 355.17 430.5"
                ></polygon>
                <polygon
                  class="cls-3"
                  points="355.17 430.5 410.33 375.33 410.33 430.5 355.17 430.5"
                ></polygon>
                <line class="cls-4" x1="395" x2="395" y1="410" y2="419"></line>
                <line
                  class="cls-4"
                  x1="399.5"
                  x2="390.5"
                  y1="414.5"
                  y2="414.5"
                ></line>
              </svg>
              <br />

              <img src={networkr} alt="network logo" />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <div class="ag-create_title-item">
                Web3 smart contract protocol development & next generation token
                utility protocols.
                <br />
              </div>
            </a>
          </Grid>

          <Grid
            item
            md={12}
            sm={12}
            lg={12}
            xs={12}
            style={{
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <a
              href="https://ferrum.network/"
              class="js-create_video ag-create_item"
              target="_blank"
            >
              <svg
                viewBox="0 0 411 431"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
              >
                <polygon
                  class="cls-1"
                  points="0.5 430.5 340.31 430.5 410.5 361.03 410.5 0.5 0.5 0.5 0.5 430.5"
                ></polygon>
                <polygon
                  class="cls-2"
                  points="355.17 430.5 410.33 375.33 410.33 430.5 355.17 430.5"
                ></polygon>
                <polygon
                  class="cls-3"
                  points="355.17 430.5 410.33 375.33 410.33 430.5 355.17 430.5"
                ></polygon>
                <line class="cls-4" x1="395" x2="395" y1="410" y2="419"></line>
                <line
                  class="cls-4"
                  x1="399.5"
                  x2="390.5"
                  y1="414.5"
                  y2="414.5"
                ></line>
              </svg>
              <br />

              <img src={networkl} alt="network logo" style={{ width: "70%" }} />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              
              <br />
              <br />
              <div class="ag-create_title-item">
                Pioneering interoperability 2.0 with a next generation
                multichain messaging engine powering a bridgeless future.
                <br />
              </div>
            </a>
          </Grid>

          <Grid
            item
            md={12}
            sm={12}
            lg={12}
            xs={12}
            style={{
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <a
              href="https://www.joinmidnightlabs.com/"
              class="js-create_video ag-create_item"
              target="_blank"
            >
              <svg
                viewBox="0 0 411 431"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
              >
                <polygon
                  class="cls-1"
                  points="0.5 430.5 340.31 430.5 410.5 361.03 410.5 0.5 0.5 0.5 0.5 430.5"
                ></polygon>
                <polygon
                  class="cls-2"
                  points="355.17 430.5 410.33 375.33 410.33 430.5 355.17 430.5"
                ></polygon>
                <polygon
                  class="cls-3"
                  points="355.17 430.5 410.33 375.33 410.33 430.5 355.17 430.5"
                ></polygon>
                <line class="cls-4" x1="395" x2="395" y1="410" y2="419"></line>
                <line
                  class="cls-4"
                  x1="399.5"
                  x2="390.5"
                  y1="414.5"
                  y2="414.5"
                ></line>
              </svg>
              <br />

              <img src={networkc} alt="network logo" style={{ width: "18%" }} />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <div class="ag-create_title-item">
                A private community for collectors and builders as well as a
                launchpad for web3 native products.
                <br />
              </div>
            </a>
          </Grid>
        </Grid>
        <div className=" threeicons">
          <div className="network_container"></div>
        </div>
        <div className="cstyle">
          <div className="card-shadow"></div>
          <div className="row makerow">
            <div className="col-md-6 wholediv">
              <div className="ryanlogo">
                <img src={current} alt="side_img" />
              </div>
            </div>
            <div className="col-md-6  centeralign">
              <div>
                <div className="profile-img">
                  <img
                    height="100%"
                    width="100%"
                    alt="profile"
                    src={profileimage}
                  />
                </div>
                <p className="ryan">NuFracTal</p>
                <p className="founder">Founder</p>
                <p className="founderpx">A passion for web3</p>
                <Link to="about" className="addmore">
                  about me
                  <BsArrowRight fontSize="35px" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Network;
